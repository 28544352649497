import Image from "next/image";
import Slider from "react-slick";

const images = [
  {
    url: "https://d3w216np43fnr4.cloudfront.net/1920x1080/8388/123038/1.jpg",
    alt: "Asheville Skyline View",
  },
  {
    url: "https://d3w216np43fnr4.cloudfront.net/1920x1080/8388/123038/2.jpg",
    alt: "Sign outside the Whitney Commercial Real Estate offices",
  },
  {
    url: "https://d3w216np43fnr4.cloudfront.net/1920x1080/8388/123038/3.jpg",
    alt: "Sign outside the Whitney Commercial Real Estate offices",
  },
  {
    url: "https://d3w216np43fnr4.cloudfront.net/1920x1080/8388/123038/6.jpg",
    alt: "Aerial view of the Ridgefield Business Park in Asheville, NC",
  },
  {
    url: "https://d3w216np43fnr4.cloudfront.net/1920x1080/8388/123038/4.jpg",
    alt: "Sign outside the Whitney Commercial Real Estate offices",
  },
  {
    url: "https://d3w216np43fnr4.cloudfront.net/1920x1080/8388/123038/5.jpg",
    alt: "Front of the 1100 Ridgefield Blvd building",
  },
];

export default function Banner() {
  return (
    <section className="relative block w-full h-[500px] bg-blue overflow-hidden">
      <Slider
        className="h-full"
        speed={1000}
        autoplaySpeed={7000}
        autoplay
        fade
        dots={false}
      >
        {images.map((image) => (
          <div key={image.url}>
            <Image
              alt={image.alt || ""}
              height="576"
              priority
              src={image.url || ""}
              width="1920"
              className="object-cover w-full h-full opacity-30"
            />
          </div>
        ))}
      </Slider>
      <div className="absolute top-0 flex flex-col items-center content-center justify-center w-full h-full py-auto">
        <h2 className="max-w-4xl mx-auto text-5xl tracking-wide text-center uppercase font-display text-gray-50">
          Asheville&apos;s Full-Service Commercial Real Estate Firm
        </h2>
        <p className="max-w-5xl pt-3 mx-auto text-[14px] font-display tracking-[2px] text-center text-white uppercase">
          We help you buy, sell, lease, manage, improve, and renovate commercial
          real estate in Asheville and throughout the Western North Carolina
          region.
        </p>
      </div>
    </section>
  );
}
