import { Helmet } from "react-helmet";

import Footer from "@/components/footer";
import Header from "@/components/header";
import Meta from "@/components/meta";

import {
  companyInfoQueryResponse,
  pageQueryResponse,
  personQueryResponse,
} from "@/lib/sanityDangerousTypes";

const getSEOValues = ({ page }: { page: pageQueryResponse | undefined }) => {
  if (page) {
    const { seoDescription, seoTitle } = page;
    return { seoDescription, seoTitle };
  }

  return { seoDescription: "", seoTitle: "" };
};

type Props = {
  bodyClass: string;
  children: React.ReactNode;
  companyInfo: companyInfoQueryResponse | undefined;
  page: pageQueryResponse | undefined;
  person?: personQueryResponse | undefined;
};

export default function LayoutHome({
  bodyClass,
  children,
  companyInfo,
  page,
}: Props) {
  const { logoDark } = companyInfo || {};
  const { seoDescription, seoTitle } = getSEOValues({ page });
  const canoncialUrl = `https://www.whitneycre.com`;

  const titleTag = seoTitle;

  return (
    <>
      <Helmet bodyAttributes={{ class: bodyClass }} defer={false}>
        <meta name="twitter:title" content={titleTag} />
        <meta property="og:title" content={titleTag} />

        <meta name="description" content={seoDescription} />
        <meta name="twitter:description" content={seoDescription} />
        <meta property="og:description" content={seoDescription} />

        <link rel="canonical" href={canoncialUrl} />
        <meta name="twitter:url" content={canoncialUrl} />
        <meta property="og:url" content={canoncialUrl} />

        <meta name="twitter:image" content={logoDark} />
        <meta property="og:image" content={logoDark} />
        <meta property="og:type" content="website" />
      </Helmet>

      <Meta companyInfo={companyInfo} seoTitle={titleTag} />

      <Header companyInfo={companyInfo} />

      {children}

      <Footer companyInfo={companyInfo} />
    </>
  );
}
