import { useEffect, useState } from "react";
import Image from "next/image";
import { pageQueryResponseTestimonial } from "@/lib/sanityDangerousTypes";
import Slider from "react-slick";

export default function Testimonials({
  testimonials,
}: {
  testimonials: pageQueryResponseTestimonial[] | undefined;
}) {
  const numTestimonials = testimonials?.length || 0;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const speed = 5000;

  useEffect(() => {
    const timer = setTimeout(() => {
      const newIndex =
        selectedIndex + 1 === numTestimonials ? 0 : selectedIndex + 1;
      setSelectedIndex(newIndex);
    }, speed);

    return () => clearTimeout(timer);
  }, [numTestimonials, selectedIndex]);

  if (!testimonials) return null;

  return (
    <section className="relative w-full h-[570px]">
      <div className="items-center justify-center w-full h-full bg-blue-800">
        <Image
          alt="testimonial-background-image"
          height="576"
          priority={selectedIndex === 0}
          src={"https://dl6bglhcfn2kh.cloudfront.net/phpMzymZQ.jpg"}
          width="1920"
          className="object-cover w-full h-full opacity-20"
        />

        <Slider
          className="absolute flex items-center justify-center w-1/2 h-full transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
          speed={1000}
          autoplaySpeed={5000}
          autoplay
          fade
        >
          {testimonials.map((testimonial) => (
            <div
              className="w-full md:px-16"
              key={testimonial._id}
              itemType="http://schema.org/Review"
            >
              <p
                className="font-serif text-4xl italic text-center text-white font-extralight"
                itemProp="reviewBody"
              >
                {testimonial.body}
              </p>
              <div className="pt-12 text-2xl font-light tracking-widest text-center text-white capitalize font-display">
                <span itemProp="author">{testimonial.citation}</span>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}
