import Link from "next/link";
import {
  ArrowPathIcon,
  ArrowRightCircleIcon,
  CloudArrowUpIcon,
  LockClosedIcon,
} from "@heroicons/react/20/solid";

const features = [
  {
    name: "Brokerage",
    description:
      "We know that navigating transactions for commercial properties can be complex from any angle, but it doesn't have to be difficult. All our experience - whether it's finding the right buyer, helping you sell, facilitating an ideal leasing arrangement, or determining the perfect investment opportunity -means we're helping you maximize value no matter which side of a deal you are on.",
    href: "/brokerage",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Property Management",
    description:
      "We're your dedicated commercial real estate problem-solvers, pairing personalized care with the expertise needed to maximize your property's value. As an Accredited Management Organization (AMO) with expertise in managing diverse property types, we offer flexible services tailored to your needs.",
    href: "/property-management",
    icon: LockClosedIcon,
  },
  {
    name: "General Contracting",
    description:
      'Whitney Construction & Development, Inc. is licensed for "Unlimited" projects by the NC Licensing Board for General Contractors, focusing on renovations and new construction. We offer transparent, open-book services, keeping clients informed while managing all aspects of their projects efficiently.',
    href: "/general-contracting",
    icon: ArrowPathIcon,
  },
];

export default function Welcome() {
  return (
    <section id="wrapper">
      <div className="py-10 bg-white sm:py-16">
        <div className="px-6 mx-auto max-w-7xl lg:px-8">
          <div className="max-w-3xl mx-auto lg:text-center">
            <h2 className="text-2xl font-semibold leading-7 text-blue-700 font-display">
              Your One Stop Shop
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-blue font-display sm:text-4xl">
              For Commercial Real Estate
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-700">
              Whitney Commercial Real Estate is the only firm in Western North
              Carolina offering wholly owned, specialized services in commercial
              real estate brokerage, property management, and general
              contracting & construction. Let us use our expertise to
              problem-solve for you.
            </p>
          </div>
          <div className="max-w-2xl mx-auto mt-12 sm:mt-16 lg:mt-20 lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="flex flex-col">
                  <dt className="flex items-center text-xl font-semibold leading-7 text-gray-800 gap-x-3">
                    <feature.icon
                      className="flex-none w-6 h-6 text-blue-mid"
                      aria-hidden="true"
                    />
                    {feature.name}
                  </dt>
                  <dd className="flex flex-col flex-auto mt-4 text-base leading-7 text-gray-800">
                    <p className="flex-auto">{feature.description}</p>
                    <p className="flex justify-end mt-3">
                      <Link
                        href={feature.href}
                        className="flex flex-row items-center px-3 py-2 text-base font-semibold leading-6 no-underline transition-colors duration-500 rounded-lg text-blue-mid bg-blue-50 hover:bg-blue-100"
                      >
                        Learn more
                        <ArrowRightCircleIcon className="w-6 h-6 ml-1" />
                      </Link>
                    </p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </section>
  );
}
